// extracted by mini-css-extract-plugin
export var column = "DashboardCreateProvider__column__gZ0P5";
export var contactPersonContainer = "DashboardCreateProvider__contactPersonContainer__J4VpJ";
export var contactPersonDesc = "DashboardCreateProvider__contactPersonDesc__xKNmZ";
export var contactPersonDescContainer = "DashboardCreateProvider__contactPersonDescContainer__Os7ud";
export var contactPersonDescData = "DashboardCreateProvider__contactPersonDescData__MS8ry";
export var contactPersonName = "DashboardCreateProvider__contactPersonName__ahFNI";
export var divider = "DashboardCreateProvider__divider__yW77W";
export var doubleColumnContainer = "DashboardCreateProvider__doubleColumnContainer__oPWvO";
export var firstNameContainer = "DashboardCreateProvider__firstNameContainer__PyigT";
export var formRow = "DashboardCreateProvider__formRow__rO3Fc";
export var h1 = "DashboardCreateProvider__h1__kGzVW";
export var h2 = "DashboardCreateProvider__h2__wAU4D";
export var iconMargin = "DashboardCreateProvider__iconMargin__h9Rsg";
export var imgPerson = "DashboardCreateProvider__imgPerson__B7eLB";
export var lastNameContainer = "DashboardCreateProvider__lastNameContainer__Xvvj8";
export var leftContent = "DashboardCreateProvider__leftContent__WqB4K";
export var messageField = "DashboardCreateProvider__messageField__uMMR0";
export var pageContainer = "DashboardCreateProvider__pageContainer__qWZiy";
export var pageContent = "DashboardCreateProvider__pageContent__pzjMX";
export var personContainerDesktop = "DashboardCreateProvider__personContainerDesktop__bFlO8";
export var personContainerMobile = "DashboardCreateProvider__personContainerMobile__VqcYT";
export var phoneContainer = "DashboardCreateProvider__phoneContainer__DJXjk";
export var phoneGhostContainer = "DashboardCreateProvider__phoneGhostContainer__IAKFs";
export var rightContent = "DashboardCreateProvider__rightContent__edGTH";
export var row = "DashboardCreateProvider__row__bmJYz";
export var submitMessage = "DashboardCreateProvider__submitMessage__daKfT";
export var submitRow = "DashboardCreateProvider__submitRow__cTeE6";